import styled from "styled-components";

const FooterWrapper = styled.footer`
  background: #191919;

  img.footerImg {
    margin-top: -40px;
    margin-bottom: -55px;
  }
  .contactBox {
    margin-top: 15px;
  }

  ul li {
    margin-bottom: 10px;
  }

  h3 {
    font-size: 20px;
    letter-spacing: 0;
    margin-bottom: 15px;
  }

  li,
  h3,
  p, p a {
    color: #ffffff;
  }

  &.footercpyr {
    background: #ffffff;
    padding: 15px 0;
    text-align: right;

    p {
      color: #000000;
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 767px) {
    .footer_conttainerow {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  @media only screen and (min-width: 767px) {
    .col-md-4:nth-child(2) {
      padding-left: 50px;
      padding-right: 50px;
    }
  }
`;

export default FooterWrapper;
