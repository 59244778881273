import styled from "styled-components";

const SectionWrapper = styled.div`
  padding: 81px 0;

  h3 {
    font-size: 26px;
  }

  h4 a {
    color: #ffffff;

    &:hover {
      text-decoration: underline;
    }
  }

  .location-details-wrap .locations p a {
    color: #ffffff;
  }

  ul.social-icons {
    margin-bottom: 0;
  }

  ul.social-icons li a {
    background: #d7903f;
    color: #ffffff;
    padding: 11px 13px;
    margin: 5px;
    border-radius: 100%;
  }

  .location-details-wrap {
    border-radius: 20px;
    background: linear-gradient(180deg, #ffa238 0%, #a8600e 100%);
    padding: 25px;

    .locations {
      border-bottom: 1px solid #a8600e;
      margin-bottom: 25px;

      img {
        margin-top: -5px;
      }

      p {
        color: #fff;
        margin-left: 35px;
      }

      h4 {
        font-size: 18px;
        margin-bottom: 0;
        color: #ffffff;
      }
    }
  }

  @media only screen and (max-width: 1440px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 767px) {
    padding: 42px 15px;

    input[type="date"]:not(.has-value):before{
      color: #858585;
      content: attr(placeholder);
      position: absolute;
    }

    h3 {
      text-align: center;
    }
    ul.topRight-t.flex.social-icons {
      justify-content: center !important;
      margin-top: 25px;
      margin-bottom: 25px;
    }
    .form_ARaea p {
      text-align: center;
    }
    .form_ARaea form#footerContactForm {
      padding-left: 10px;
      padding-right: 10px;
    }

    .locations .loc1.col-md-6,
    .locations .loc2.col-md-6 {
      padding: 0;
      margin-bottom: 15px;
    }
    .location-details-wrap .locations h4 {
      font-size: 16px;
    }
    .locations .loc2.col-md-6 .flex {
      justify-content: flex-start !important;
    }
    .locations .loc1.col-md-6 img {
      margin-left: -5px;
    }
    .location-details-wrap .locations p {
      color: #fff;
      margin-left: -10px;
    }
  }
`;

export const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  @media only screen and (max-width: 1700px) {
    padding: 0 0px;
  }
  @media only screen and (max-width: 1360px) {
    padding: 0px;
  }
  @media only screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const ImageWrapper = styled.div`
  width: calc(100% - 507px);
  padding-right: 75px;
  position: relative;
  @media only screen and (max-width: 1200px) {
    width: 60%;
    padding-right: 50px;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
  }
  @media only screen and (max-width: 767px) {
    width: 100%;
    padding-right: 0;
    margin-bottom: 30px;
    transform: rotateY(-180deg);
  }
`;

export const TextWrapper = styled.div`
  width: 507px;
  @media only screen and (max-width: 991px) {
    padding-left: 50px;
  }
  @media only screen and (max-width: 767px) {
    padding-left: 0;
    width: 100%;
  }

  h2 {
    @media only screen and (min-width: 1441px) {
      margin-bottom: 60px;
    }
    @media only screen and (min-width: 1440px) {
      margin-bottom: 40px;
    }
    @media only screen and (min-width: 767px) {
      margin-bottom: 30px;
    }
  }

  p {
    margin-bottom: 30px;
  }

  .learn__more-btn {
    margin-top: 20px;
  }


`;

export default SectionWrapper;
