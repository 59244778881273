import styled from "styled-components";

const NavbarWrapperCSS = styled.div`

.navbar-nav .dropdown-menu.show {
  display: block !important;
}

.navbar-nav .dropdown-menu {
  display: none !important;
}

  @media only screen and (max-width: 800px) {

    .sticky-outer-wrapper {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 9;
  }
  
    div#hero__Area {
      margin-top: 56px;
  }

    .navbar-toggler svg {
      font-size: 24px;
      height: 22px;
      width: 20px;
  }
    .topbarRow {
      display: none;
    }
    .navbar img {
      width: 160px !important;
    }
    .navbar-expand-lg {
      padding: 0 10px;
    }
  }
`;

const Container = styled.div`
  .radianceMainLogo img {
    width: 200px;
  }

  svg {
    font-size: 16px;
    height: 15px;
    width: 15px;
  }

  display: block;

  .sticky-nav-active span.call-now a {
    color: #c07c34;
  }

  .sticky-nav-active span.call-now {
    color: #333333;
  }
`;

export { Container, NavbarWrapperCSS };
