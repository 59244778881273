import React, { useContext } from "react";
import { Container, NavbarWrapperCSS } from "./navbar.style";
import logo from "common/assets/image/interior/radiance-logo.png";
import NavbarWrapper from "common/components/Navbar";
import Logo from "common/components/UIElements/Logo";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faPhone,
  faEnvelope,
  faClock,
  faBars,
} from "@fortawesome/free-solid-svg-icons";

const Navbar = ({ navbarStyle, logoStyle }) => {
  const location = useLocation();
  const isActive = location.pathname === "/";

  return (
    <NavbarWrapperCSS>
      <NavbarWrapper className="mainNav">
        <Container className="topbarRow">
          <div className="myContainer topBar flex">
            <ul className="topLeft-t flex mr-auto">
              <li>
                <a href="tel:18001031191">
                  <FontAwesomeIcon icon={faPhone} /> +91-99585-85649
                </a>
              </li>
              <li>
                <a href="mailto:contact@radiancehairstudio.com">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  contact@radiancehairstudio.com
                </a>
              </li>
            </ul>
            <ul className="topRight-t flex">
              <li>
                <p style={{ marginBottom: "0", color: "#ffffff" }}>
                  <FontAwesomeIcon icon={faClock} /> Contact hour: 11AM - 8PM
                </p>
              </li>
            </ul>
          </div>
        </Container>
        <Container className="mainHeadernav">
          <nav className="myContainer navbar navbar-expand-lg navbar-dark">
            <Link to='/'><img src={logo} width="170" height="44" alt="Radiance Hair Studio" className="logoWrapper radianceMainLogo" /></Link>

            
        
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <FontAwesomeIcon icon={faBars} />
            </button>

            <div className="collapse navbar-collapse" id="navbarNav">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active pl-2 pr-2">
                  <Link
                    itemprop="url"
                    className="nav-link"
                    activeClassName="nav-link active"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item dropdown pl-2 pr-2">
                  <Link
                    to="/about.php"
                    className={`nav-link dropdown-toggle ${
                      location.pathname === "/about/" ? "active" : ""
                    }`}
                    activeClassName="active"
                    id="navbarDropdown4"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    About Us
                  </Link>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown4"
                  >
                    <div className="dropdown-item">
                      <ul>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            activeClassName="active"
                            to="/about.php"
                            className={`fff ${
                              location.pathname === "/about" ? "active" : ""
                            }`}
                          >
                            About
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            activeClassName="active"
                            to="/testimonials.php"
                            className=""
                          >
                            Testimonials & Reviews
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            activeClassName="active"
                            to="/gallery.php"
                            className=""
                          >
                            Gallery
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item pl-2 pr-2">
                  <Link
                    itemprop="url"
                    className="nav-link"
                    activeClassName="nav-link active"
                    to="/services.php"
                  >
                    Our Services
                  </Link>
                </li>
                <li className="nav-item dropdown pl-2 pr-2">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Men’s Hair Solution
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown"
                  >
                    <div className="dropdown-item">
                      <ul>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/wigs-for-men.php"
                          >
                            Wigs for Men
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-patch-mens.php"
                          >
                            Hair Patch for Men
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-replacement-for-men.php"
                          >
                            Hair Replacement
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/non-surgical-hair-replacement-for-men.php"
                          >
                            Non-Surgical Hair Replacement
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-extensions-for-men.php"
                          >
                            Human Hair Extension
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/customized-wigs-for-men.php"
                          >
                            Customized Wigs
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-bonding-for-men.php"
                          >
                            Hair Bonding
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-weaving-for-mens.php"
                          >
                            Hair Weaving
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/tape-in-and-clip-in-hair-extension.php"
                          >
                            Tape In & Clip In on Hair Extensions
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/wigs-for-men-cancer-patients.php"
                          >
                            Wigs for Cancer Patients
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-fixing.php"
                          >
                            Hair Fixing
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown pl-2 pr-2">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown2"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Women’s Hair Solution
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown2"
                  >
                    <div className="dropdown-item">
                      <ul>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/customized-wigs-for-women.php"
                          >
                            Customized Wigs for Women
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/wigs-for-womens.php"
                          >
                            Wig for Women's
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-replacement-for-women.php"
                          >
                            Hair Replacement
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/non-surgical-hair-replacement.php"
                          >
                            Non-Surgical Hair Replacement
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/volumizer-hair-additions-for-women.php"
                          >
                            Volumizer Hair Additions
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-extention-for-womens.php"
                          >
                            Hair Extensions
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-bonding-for-women.php"
                          >
                            Hair Bonding
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/hair-weaving-for-women.php"
                          >
                            Hair Weaving
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/tape-in-and-clip-in-womens.php"
                          >
                            Tape In & Clipping In Extension
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/womens-wigs-for-cancer-patients.php"
                          >
                            Wig for cancer patients
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item dropdown pl-2 pr-2">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#"
                    id="navbarDropdown3"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Resources
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdown3"
                  >
                    <div className="dropdown-item">
                      <ul>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/blogs.php"
                          >
                            Blogs
                          </Link>
                        </li>
                        <li itemprop="name" className="pt-2 pb-2">
                          <Link
                            itemprop="url"
                            className=""
                            activeClassName="active"
                            to="/faq.php"
                          >
                            FAQs
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="nav-item pl-2 pr-2">
                  <Link
                    itemprop="url"
                    className="nav-link"
                    activeClassName="nav-link active"
                    to="/contact.php"
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </Container>
      </NavbarWrapper>
    </NavbarWrapperCSS>
  );
};

export default Navbar;
